import React, { useState, useEffect } from 'react';
import './Admin.scss';
import SocialPosts from '../SocialPosts/SocialPosts';
import { useStoreState } from 'pullstate';
import Reporting from '../Reporting/Reporting';
import { AccountStore } from '../../Stores/AccountStore';
import APIFetch from '../../Utilities/APIFetch';
import CircularProgress from '../../Components/CircularProgress/CircularProgress';
import SystemManagement from '../SystemManagement/SystemManagement';
import ReportingIcon from '../../Assets/images/admin/ReportingIcon.svg';
import SocialIcon from '../../Assets/images/admin/SocialIcon.svg';
import DistributeIcon from '../../Assets/images/admin/DistributeIcon.svg';
import AwardsIcon from '../../Assets/images/admin/AwardsIcon.svg';
import UsageGuidanceIcon from '../../Assets/images/admin/UsageGuidanceIcon.svg';
import Scrollbar from 'react-scrollbars-custom';
import DistributionItem from '../../Components/DistributionItem/DistributionItem';
import SendAward from '../SendAward/SendAward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import RoleAssignment from '../RoleAssignment/RoleAssignment';
import DetailsPopOver from '../../Components/DetailsPopOver/DetailsPopOver';
import ConfirmModal from '../../Components/ConfirmModal/ConfirmModal';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';

const activeOptions = [
    { text: "Last 7 days", value: 7, result: null },
    { text: "Last 14 days", value: 14, result: null },
    { text: "Last 28 days", value: 28, result: null }
];

const Admin = () => {
    const role = useStoreState(AccountStore, s => s.status.role);
    const [adminStats, setAdminStats] = useState(null);
    const [distributionDetails, setDistributionDetails] = useState(null);
    const [distributions, setDistributions] = useState(null);
    const [redemptionsEnabled, setRedemptionsEnabled] = useState(null);
    const [lastAccountCleanup, setLastAccountCleanup] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [showActiveOptions, setShowActiveOptions] = useState(false);
    const [activeOption, setActiveOption] = useState(activeOptions[0]);
    const [busy, setBusy] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [confirmTitle, setConfirmTitle] = useState(null);
    const [confirmContent, setConfirmContent] = useState(null);
    var content = null;
    
    const getDashboard = () => {
        if(role === 'Admin') {
            APIFetch('GET', `reporting/quickstats`)
            .then((result) => {
                if(result && result.ok) {
                    setAdminStats(result.data);
                    activeOptions[0].result = result.data.activeUsers;
                    setActiveOption({...activeOptions[0]});
                }
            })
            .catch(e => {
            });
        }
        if(role === 'Admin') {
            APIFetch('GET', `system/distributions`)
            .then((result) => {
                if(result && result.ok) {
                    setDistributions(result.data);
                }
            })
            .catch(e => {
            });

            APIFetch('GET', `system/distribute`)
            .then((result) => {
                if(result && result.ok) {
                    setDistributionDetails(result.data);
                }
            })
            .catch(e => {
            });

            APIFetch('GET', `system/settings`)
            .then((result) => {
                if(result && result.ok) {
                    var redemptionSetting = result.data.find(s => s.name === "AllowRedemptions");
                    setRedemptionsEnabled(redemptionSetting ? redemptionSetting.value : "false");

                    var cleanupSetting = result.data.find(s => s.name === "LastAccountCleanup");
                    setLastAccountCleanup(cleanupSetting ? cleanupSetting.value : null);
                }
            })
            .catch(e => {
            });
        }
    }

    const getActiveStat = (activeId) => {
        if(role === 'Admin') {
            APIFetch('GET', `reporting/quickstats/activity/${activeOptions[activeId].value}`)
            .then((result) => {
                if(result && result.ok) {
                    activeOptions[activeId].result = result.data;
                    setActiveOption(activeOptions[activeId]);
                }
            })
            .catch(e => {
            });
        }
    }

    const onUpdate = () => {
        APIFetch('GET', `system/distributions`)
        .then((result) => {
            if(result && result.ok) {
                setDistributions(result.data);
            }
        })
        .catch(e => {
        });
    }

    const toggleRedemptions = (value) => {
        APIFetch('POST', `system/allowredemptions/${value}`)
        .then((result) => {
            if(result && result.ok) {
                setRedemptionsEnabled(value);
            }
        })
        .catch(e => {
        });
    }

    const cleanupAccounts = () => {
        if(!busy) {
            setBusy(true);

            APIFetch('GET', 'system/accountcleanup')
            .then(result => {
                if(result.ok) {
                    setBusy(false);
                    setSuccessMessage(`${result.data.count} accounts successfully marked as deleted.`);
                    setLastAccountCleanup(result.data.lastCleanup);
                    getDashboard();
                } else {
                    setErrorText("Failed to run account clean-up successfully, please contact Alt Labs for assistance.");
                }
            })
            .catch(e => {
                setErrorText("Failed to run account clean-up successfully, please contact Alt Labs for assistance.");
            })
            .finally(r => {
                setBusy(false);
            })
        }
    }

    const resetSharePots = (confirmed) => {
        if (confirmed) {
            setConfirmTitle(null);
            setConfirmContent(null);
            setConfirmCallback(null);

            if(!busy) {
                setBusy(true);
                
                APIFetch('GET', 'system/resetsharepots')
                .then(result => {
                    if(result.ok) {
                        setBusy(false);
                        setSuccessMessage(`Share pots successfully reset.`);
                        getDashboard();
                    } else {
                        setErrorText("Failed to run account clean-up successfully, please contact Alt Labs for assistance.");
                    }
                })
                .catch(e => {
                    setErrorText("Failed to run account clean-up successfully, please contact Alt Labs for assistance.");
                })
                .finally(r => {
                    setBusy(false);
                })
            }
        } else {
            setConfirmTitle("Confirm Reset");
            setConfirmContent(`Are you sure you wish to reset all share pots? This action cannot be reversed.`);
            setConfirmCallback(() => { return () => { resetSharePots(true); }});
        }
    }

    useEffect(() => {
        getDashboard();
    }, [])

    switch (currentPage) {
        case 1:
            content = <SocialPosts onBack={() => { getDashboard(); setCurrentPage(0); }}/>
            break;
        case 2:
            content = <SystemManagement onBack={() => { getDashboard(); setCurrentPage(0); }}/>;
            break;
        case 3:
            content = <Reporting onBack={() => { getDashboard(); setCurrentPage(0); }}/>;
            break;
        case 4:
            content = <SendAward onBack={() => { getDashboard(); setCurrentPage(0); }}/>;
            break;
        case 5:
            content = <TermsOfUse onBack={() => { getDashboard(); setCurrentPage(0); }}/>;
            break;
        case 6:
            content = <RoleAssignment onBack={() => { getDashboard(); setCurrentPage(0); }}/>;
            break;
        default:
            content = <Scrollbar className="full-width full-height secondary-background flex" scrollerProps={{tabIndex: 0}}>
                {redemptionsEnabled === "false" ? <div className="alert-bar">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="alert-icon" />Redemptions are currently disabled!
                </div> : null }
                <div className="menu-cards flex-row margin-top-2">
                    { role === 'Admin' || role === 'Reporting' || role === 'SeniorManager' ?<div className="menu-card flex-row flex-grow box-shadow">
                        <img src={ReportingIcon} alt="" className="margin-left-1"/>
                        <div className="text-md margin-left-3 margin-right-3">Reporting</div>
                        <div className="btn btn-positive bold admin-button" onClick={() => { setCurrentPage(3) }} tabIndex={0} role="button" aria-label="Open Reporting">
                            Open
                        </div>
                    </div> : null }
                    { role === 'Admin' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="coin-distribution">
                            <img src={DistributeIcon} alt="" className="margin-left-1"/>
                            <div className="text-md margin-left-3 margin-right-3">Allocate Coins</div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { setCurrentPage(2) }} tabIndex={0} role="button" aria-label="Open Coin Allocation">
                                Open
                            </div>
                        </div> : null
                    }
                    { role === 'Admin' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="role-assignment">
                            <img src={DistributeIcon} alt="" className="margin-left-1"/>
                            <div className="text-md margin-left-3 margin-right-3">Assign Roles</div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { setCurrentPage(6) }} tabIndex={0} role="button" aria-label="Open Coin Allocation">
                                Open
                            </div>
                        </div> : null
                    }
                    { role === 'Admin' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="social-post">
                            <img src={SocialIcon} alt="" className="margin-left-1"/>
                            <div className="text-md margin-left-3 margin-right-3">Social Posts</div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { setCurrentPage(1) }} tabIndex={0} role="button" aria-label="Open Social Post Management">
                                Open
                            </div>
                        </div> : null
                    }
                    { role === 'Admin' || role === 'CustomerRelations' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="awards">
                            <img src={AwardsIcon} alt="" className="margin-left-1"/>
                            <div className="text-md margin-left-3 margin-right-3">Awards</div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { setCurrentPage(4) }} tabIndex={0} role="button" aria-label="Open Grant Award">
                                Open
                            </div>
                        </div>: null
                    }
                    { role === 'Admin' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="terms-of-use">
                            <img src={UsageGuidanceIcon} alt="" className="margin-left-1"/>
                            <div className="text-md margin-left-3 margin-right-3">Usage Guidance</div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { setCurrentPage(5) }} tabIndex={0} role="button" aria-label="Open Usage Guidance Management">
                                Open
                            </div>
                        </div> : null
                    }
                    { role === 'Admin' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="account-cleanup">
                            <img src={DistributeIcon} alt="" className="margin-left-1"/>
                            <div className='flex-col'>
                                <div className="text-md margin-left-3 margin-right-3">Clean-up Old Accounts</div>
                                { lastAccountCleanup ? <div className="text-sm margin-left-3 margin-right-3 text-grey">Last Run {lastAccountCleanup}</div> : <div className="text-sm margin-left-3 margin-right-3"></div> }
                            </div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { cleanupAccounts() }} tabIndex={0} role="button" aria-label="Trigger A Clean-up Of Old Accounts">
                                Run
                            </div>
                        </div> : null
                    }
                    { role === 'Admin' ?
                        <div className="menu-card flex-row flex-grow box-shadow" key="share-pot-reset">
                            <img src={DistributeIcon} alt="" className="margin-left-1"/>
                            <div className='flex-col'>
                                <div className="text-md margin-left-3 margin-right-3">Reset Share Pots</div>
                            </div>
                            <div className="btn btn-positive bold admin-button" onClick={() => { resetSharePots() }} tabIndex={0} role="button" aria-label="Trigger A Reset Of Share Pot Balances">
                                Run
                            </div>
                        </div> : null
                    }
                </div>
                { adminStats ? <div className="admin-stats flex-row">
                    <div className="flex flex-grow">
                        <div className="stats-card stats-card-text">
                            <div className="stats-text">
                                Total Users
                            </div>
                            <div className="text-xxlg">
                                {adminStats.totalUsers}
                            </div>
                        </div>
                        <div className="stats-card stats-card-text">
                            <div className="stats-text">
                                <span>Active Users ({activeOption ? activeOption.text : "Loading..."})</span>
                                <span className="drop-icon pointer" onClick={() => setShowActiveOptions(!showActiveOptions) }><FontAwesomeIcon icon={showActiveOptions ? faChevronUp : faChevronDown} /></span>
                                {showActiveOptions ? <div className="drop-list">
                                    {activeOptions.map((o, index) => <div key={index} className="drop-list-item" onClick={() => { setShowActiveOptions(false); if(o.result === null) { setActiveOption(null); getActiveStat(index); } else { setActiveOption(o); }; }}>{o.text}</div>)}
                                </div> : null }
                            </div>
                            <div className="text-xxlg">
                                {activeOption ? activeOption.result : "-"}{activeOption ? <span className="text-xsm">({((activeOption.result/adminStats.totalUsers) * 100).toFixed(0)}%)</span> : null }
                            </div>
                        </div>
                    </div>
                    <div className="stats-card stats-card-text">
                        <div className="stats-text">
                            GiftPay Balance
                        </div>
                        <div className="text-xxlg">
                            {adminStats.balance * 100}
                        </div>
                        <div className="text-xxlg">
                            (£{adminStats.balance})
                        </div>
                        { redemptionsEnabled !== null ? <div className={"btn bold admin-button flex-item-bottom" + (redemptionsEnabled === "true" ? " btn-negative" : " btn-positive")} onClick={() => { toggleRedemptions(redemptionsEnabled === "true" ? "false" : "true") }} tabIndex={0} role="button">
                            {redemptionsEnabled === "true" ? "Disable Redemptions" : "Enable Redemptions"}
                        </div> : null }
                    </div>
                    <div className="flex flex-grow">
                        <div className="stats-card stats-card-text">
                            <div className="stats-text">
                                Coins Ready To Spend
                            </div>
                            <div className="text-xxlg">
                                {adminStats.systemCoins}
                            </div>
                            <div className="text-xxlg">
                                (£{adminStats.systemCoins/100})
                            </div>
                        </div>
                    </div>
                    <div className="stats-card">
                        <CircularProgress size={180} strokeWidth={20} progress={adminStats.percentageComplete ? adminStats.percentageComplete.toFixed(1) : 0} />
                        <div className="margin-top-2 stats-text">
                            Users Completed Route
                        </div>
                    </div>
                    <div className="stats-card">
                        <CircularProgress size={180} strokeWidth={20} progress={adminStats.averageProgress ? adminStats.averageProgress.toFixed(1) : 0} />
                        <div className="margin-top-2 stats-text">
                            Average Route Progress
                        </div>
                    </div>
                    <div className="flex flex-grow">
                        <div className="stats-card stats-card-text">
                            <div className="stats-text">
                                Coins Left To Be Shared
                            </div>
                            <div className="text-xxlg">
                                {adminStats.shareCoins}
                            </div>
                        </div>
                        <div className="stats-card stats-card-text">
                            <div className="stats-text">
                                Average Coins Shared
                            </div>
                            <div className="text-xxlg">
                                {adminStats.averageCoinsSent ? adminStats.averageCoinsSent.toFixed(0) : 0}
                            </div>
                        </div>
                    </div>
                </div> : null }
                { role === 'Admin' ? <div className="distributions-list">
                    <div className="title">Coin Distributions</div>
                    <div className="list-header">
                        <p>Initiated By</p>
                        <p>Initiated On</p>
                        <p>Target</p>
                        <p>Type</p>
                        <p>Coins (per user)</p>
                        <p>Coins (total)</p>
                        <p>Reviewed By</p>
                        <p>Reviewed On</p>
                        <p>Status</p>
                        <p>Actions</p>
                    </div>
                    {distributions ? distributions.map(d => {
                        return <DistributionItem key={d.id} distribution={d} distributionDetails={distributionDetails} onUpdate={onUpdate}/>
                    }) : null}
                </div> : null }
            </Scrollbar>
            break;
    }

    return(
        <div className="admin-page relative full-width full-height">
            { errorText ? <DetailsPopOver onClose={() => { setErrorText(null); }} title="Oops, something went wrong!" content={errorText}/> : null }
            { successMessage ? <DetailsPopOver onClose={() => { setSuccessMessage(null); }} title="Success" content={successMessage}/> : null }
            {content}
            { busy ? <LoadingOverlay /> : null }
            {confirmTitle && confirmContent ? <ConfirmModal title={confirmTitle} content={confirmContent} confirmText="Yes" cancelText="No" onClose={() => { setConfirmTitle(null); setConfirmContent(null); setConfirmCallback(null); } } onConfirm={confirmCallback} /> : null }
        </div>
    )
}

export default Admin;